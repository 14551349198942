import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const Bike = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'Bike | HB Privileged',
      title: 'E BIKE & TACO TOUR',
      subtitle: 'TOUR OVERVIEW',
      text: "So you think you´ve done everyhting there is to do here in Vallarta, huh? We bet you haven´t done a taco tour on an electric bike! Effortllessly leave the meeting point on a brand new electric bike and visit 3 of the most famous “taquerías” downtown. You haven´t truly experienced Mexico if you haven´t eaten standing up our tacos! Nothing to do with Taco Bell! All safety gear is included and the experienced tour guide will make it so much fun! Don´t miss this 3 hours authentic mexican excursion!",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'Byke | HB Privileged',
      title: 'TOUR EN BICICLETA ELÉCTRICA Y TACO',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Así que crees que ya has hecho todo lo que hay que hacer aquí en Vallarta, eh? Apostamos que no has hecho un tour de tacos en una bicicleta eléctrica! Sal sin esfuerzo del punto de reunión en una bicicleta eléctrica totalmente nueva y visita 3 de las más famosas taquerías del centro. No has experimentado México realmente si no has comido de pie nuestros tacos! Nada que ver con Taco Bell! Todo el equipo de seguridad está incluído y un guía experimentado lo hará súper divertido! No te pierdas esta excursión auténticamente mexicana de 3 horas de duración!',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/ebike-4.jpg`,
    `${BASE_URL}/tours/ebike-3.jpg`,
    `${BASE_URL}/tours/ebike-2.jpg`,
    `${BASE_URL}/tours/ebike-1.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/tours/ebike.png`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default Bike;